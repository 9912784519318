import {
  Grid,
  Stack,
  Text,
  Button,
  Textarea,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
} from "@chakra-ui/react";
import { assets } from "../../../assets";
import { useState, useEffect } from "react";
import SelectBox from "../../Input/SelectBox";
import Btn from "../../Buttons/Btn";
import BriveCard from "./BriveCard";
import { agentList } from "../../../DB";
import InputTeb from "../../Input/Input";
import { brandColors } from "../../../theme/app.theme";
import { useServerGame } from "../../../../../hooks/serverGame";
import { Id } from "../../../../../convex/_generated/dataModel";
import { useWallet } from "@solana/wallet-adapter-react";
import { SolWalletConnectBtn } from "../../Buttons/SolConnectBTN";
import { useAction, useQuery } from "convex/react";
import { api } from "../../../../../convex/_generated/api";

const BriveBox = ({ worldId }: { worldId: Id<"worlds"> }) => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [amount, setAmount] = useState<any>(null);
  const [memory, setMemory] = useState("");
  const [memoryError, setMemoryError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const game = useServerGame(worldId);
  const playerDescriptions = game?.playerDescriptions;

  const playerList = Array.from(game?.world.players.values() ?? [])
    .filter((player) => !player.human)
    .map((player) => ({
      id: player.id,
      name: playerDescriptions?.get(player.id)?.name,
      character_id: playerDescriptions?.get(player.id)?.character_id,
    }));

  // Subscribe to crypto live chat for influence notifications
  const chatMessages =
    useQuery(api.world.cryptoLiveChatMessages, { worldId }) || [];
  const recentInfluences = chatMessages
    .filter(
      (msg) => msg.author === "SYSTEM" && msg.text.includes("inserted memory")
    )
    .slice(-5); // Show last 5 influences

  const Cards = [
    // {
    //   id: 1001,
    //   image: assets.LOGOS.logo,
    //   tittle: "Attack",
    //   amount: "200k $POD",
    //   type: "attack",
    // },
    // {
    //   id: 1002,
    //   image: assets.LOGOS.logo,
    //   tittle: "KICK",
    //   amount: "200k $POD",
    // },
    // {
    //   id: 1003,
    //   image: assets.LOGOS.logo,
    //   tittle: "Dance",
    //   amount: "200k $POD",
    // },
    // {
    //   id: 1004,
    //   image: assets.LOGOS.logo,
    //   tittle: "Influence",
    //   amount: "200k $POD",
    // },
    {
      id: 1005,
      image: assets.LOGOS.logo,
      tittle: "INFLUENCE",
      // amount: "500k $TOPD",
      amount: "ONE PER DAY",
      type: "memory",
    },
  ];

  const [card, setCard] = useState<any>({
    id: 1005,
    image: assets.LOGOS.logo,
    tittle: "INFLUENCE",
    amount: "500k $TOPD",
    type: "memory",
  });

  const { connected, publicKey } = useWallet();
  const insertMemory = useAction(api.world.insertInfluenceMemory);

  const handleInsertMemory = async () => {
    if (!memoryError && selectedAgent && memory && connected && publicKey) {
      setIsSubmitting(true);
      try {
        const targetAgent = playerList.find((p) => p.id === selectedAgent);
        console.log("targetAgent", targetAgent);
        const result = await insertMemory({
          worldId,
          walletAddress: publicKey.toString(),
          targetPlayerId: selectedAgent,
          targetAgentName: targetAgent?.name || "Unknown Agent",
          memory: memory.trim(),
        });

        if (!result.success && result.error === "DAILY_LIMIT_REACHED") {
          const nextAttemptDate = new Date(result.nextAttemptTime!);
          toast({
            title: "Daily Limit Reached",
            description: (
              <Stack>
                <Text>You've reached your daily influence limit.</Text>
                <Text>
                  Next attempt available: {nextAttemptDate.toLocaleString()}
                </Text>
                <Text color="lightgray" fontWeight="bold" mt={2}>
                  💎 Unlimited influences coming soon! 💎
                </Text>
              </Stack>
            ),
            status: "warning",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        } else if (result.success) {
          toast({
            title: "Memory Inserted Successfully",
            description: `Memory inserted into ${targetAgent?.name}`,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });

          // Reset form
          setMemory("");
          setSelectedAgent(null);
        }
      } catch (error) {
        toast({
          title: "Error inserting memory",
          description:
            error instanceof Error ? error.message : "Unknown error occurred",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Stack
      position="relative"
      gap={4}
      height={"100%"}
      w={"100%"}
      borderRadius={"20px"}
    >
      {/* Recent Influences Alert Box */}

      <Grid templateColumns="repeat(2, 1fr)" gap={6} rowGap={8} pt={4}>
        {Cards?.map((item) => (
          <BriveCard key={item.id} item={item} card={card} setCard={setCard} />
        ))}

        {card?.id !== 1004 && card?.id !== 1005 && (
          <Stack>
            <Text fontWeight={800}>Bribe to</Text>
            <SelectBox placeholder={"From Agent"} align="center">
              <option value="option1">Agent Rogue</option>
              <option value="option2">Dolos</option>
            </SelectBox>
          </Stack>
        )}

        {card?.id === 1001 && (
          <Stack>
            <Text fontWeight={800}>Action to</Text>
            <SelectBox placeholder={"To Agent"} align="center">
              <option value="option1">MOG </option>
              <option value="option2">GOAT </option>
            </SelectBox>
          </Stack>
        )}
      </Grid>

      {card?.id === 1004 && (
        <Stack gap={2}>
          <SelectBox set={setSelectedAgent} placeholder={"select agent"}>
            {agentList?.map((agent) => (
              <option key={agent.wallet} value={agent.wallet}>
                {agent.name}
              </option>
            ))}
          </SelectBox>
          <InputTeb setInputValue={setAmount} inputvalue={amount} />
        </Stack>
      )}

      {card?.id === 1005 && (
        <Stack gap={4}>
          <Text fontWeight={800}>Insert Memory Into Agent</Text>
          <SelectBox set={setSelectedAgent} placeholder={"Select target agent"}>
            {playerList?.map((agent) => (
              <option key={agent.id} value={agent.id}>
                {agent.name}
              </option>
            ))}
          </SelectBox>
          <Textarea
            placeholder="Enter the memory you want to insert... (max 300 characters)"
            value={memory}
            color={brandColors.text}
            onChange={(e) => {
              const newText = e.target.value;
              if (newText.length <= 300) {
                setMemory(newText);
                setMemoryError("");
              }
            }}
            minHeight="120px"
            maxLength={300}
            isInvalid={!!memoryError}
            bg={brandColors.primary400}
            border={`2px solid ${brandColors.stroke}`}
            _hover={{
              borderColor: brandColors.secondary,
            }}
            _focus={{
              borderColor: brandColors.secondary,
              boxShadow: "none",
            }}
            _placeholder={{
              color: `white`,
              opacity: 0.8,
            }}
            resize="vertical"
            fontSize="sm"
            fontFamily="secondary"
          />
          {memoryError && (
            <Text color="red.500" fontSize="sm">
              {memoryError}
            </Text>
          )}
          <Text fontSize="sm" color={brandColors.text} opacity={0.7}>
            {memory.length}/300 characters
          </Text>
          {!connected ? (
            <Stack className="flex items-center justify-center">
              <Text
                fontSize="md"
                color={brandColors.text}
                fontStyle={"extrabold"}
                mb={2}
              >
                Connect your wallet to insert memory
              </Text>
            </Stack>
          ) : (
            <Btn
              cta={handleInsertMemory}
              color={brandColors.secondary}
              isDisable={
                !!memoryError || !selectedAgent || !memory || isSubmitting
              }
            >
              {isSubmitting ? "INSERTING MEMORY..." : "INSERT MEMORY"}
            </Btn>
          )}
        </Stack>
      )}
      {recentInfluences.length > 0 && (
        <Box mb={4}>
          <Alert status="info" variant="solid" borderRadius="lg">
            <AlertIcon />
            <Stack>
              <AlertTitle>Recent Influences</AlertTitle>
              <Stack spacing={1}>
                {recentInfluences.map((msg) => (
                  <AlertDescription key={msg.messageUuid} fontSize="sm">
                    {msg.text}
                  </AlertDescription>
                ))}
              </Stack>
            </Stack>
          </Alert>
        </Box>
      )}
    </Stack>
  );
};

export default BriveBox;
