import React, { useState, useContext } from "react";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";

import {
  Modal,
  ModalDialog,
  ModalClose,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dropdown,
  MenuButton,
  Menu,
  MenuItem,
} from "@mui/joy";

import { Button } from "@mui/joy";
import { Trash2, Ellipsis, StepForward, CirclePlus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { deleteChat } from "../../apis/request";
import useRequest from "../../apis/useRequest";
import { PlatformContext } from "../../contexts/PlatformContext";
import "./ProfilePage.css";
import { UserAssets } from "../../types";

import { SavedChatsProps } from "./SavedChats";

export interface ChatCardProps {
  character_id: string;
  chat_id: string;
  name: string;
  last_interaction: string;
  image_url: string;
  setChats: React.Dispatch<React.SetStateAction<SavedChatsProps[]>>;
  setUserAssets: React.Dispatch<React.SetStateAction<UserAssets>>;
}
function ChatCard({
  name,
  last_interaction,
  character_id,
  chat_id,
  image_url,
  setChats,
}: ChatCardProps) {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const makeRequest = useRequest();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error("PlatformContext is undefined");
  }
  const { username, userAssets, setUserAssets, showAlert, setAlertInfo } =
    context;
  const handleDelete = async () => {
    console.log("Deleting chat with ID:", chat_id);
    try {
      const response = await makeRequest<any, void>(deleteChat(chat_id));

      setChats((prevChats) =>
        prevChats.filter((chat) => chat.chat_id !== chat_id)
      );
      setUserAssets((prevUserAssets) => ({
        ...prevUserAssets,
        chats: prevUserAssets.chats.filter((chat) => chat.chat_id !== chat_id),
      }));
    } catch (error) {
      console.error("Error deleting chat:", error);
    }

    // const deleteChat = async () => {
    //   const request_body = {
    //     item: "chat",
    //     chat_id: chat_id,
    //   };

    //   const apiUrl = process.env.REACT_APP_DELETE_API;
    //   if (!apiUrl) {
    //     throw new Error("REACT_APP_DELETE_API is not defined");
    //   }
    //   try {
    //     const response = await fetch(apiUrl, {
    //       method: "POST",
    //       body: JSON.stringify(request_body),
    //     });

    //     if (response.ok) {
    //       const data = await response.json();
    //       setChats((prevChats) =>
    //         prevChats.filter((chat) => chat.chat_id !== chat_id)
    //       );
    //       setUserAssets((prevUserAssets) => ({
    //         ...prevUserAssets,
    //         chats: prevUserAssets.chats.filter(
    //           (chat) => chat.chat_id !== chat_id
    //         ),
    //       }));
    //     } else {
    //       throw new Error(`Server responded with ${response.status}`);
    //     }
    //   } catch (error) {
    //     console.error("There was an error fetching the characters:", error);
    //   }
    // };

    setOpen(false);
  };

  return (
    <div className="w-[21rem] fastFadeInUp m-[0.4rem] h-auto rounded-lg bg-neutral-900/70 text-white">
      <div className="flex flex-row justify-between items-center">
        <img
          src={`${process.env.REACT_APP_ASSETS_BUCKET}${character_id}/display_picture.jpg`}
          alt={name}
          className="w-1/4 h-auto rounded-xl  p-1"
        />
        <div className="">
          <h3 className="font-main md:text-md text-sm truncate max-w-[10rem]">
            {name}
          </h3>
          <p className="text-xs">
            {/* Last interaction on{" "} */}
            {new Date(last_interaction).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </div>
        <Dropdown className="">
          <MenuButton className=" !m-1 !p-2 !transition-all !duration-100 !text-orange-500 hover:!bg-neutral-900 !rounded-full !border-none h-1/2 ">
            <Ellipsis size={20} />
          </MenuButton>
          <Menu className="">
            <MenuItem
              onClick={() => {
                navigate(`/chat/${character_id}`);
              }}
            >
              <CirclePlus size={20} />
              Start New
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(`/chat/${character_id}/${chat_id}`);
              }}
            >
              <StepForward size={20} />
              Continue
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClickOpen();
              }}
            >
              <Trash2 size={20} />
              Delete
            </MenuItem>
          </Menu>
        </Dropdown>
      </div>

      <Modal open={open} onClose={handleClose}>
        <ModalDialog className="!bg-neutral-800 !text-orange-500 !border-none !outline-none !min-w-[30%]">
          <ModalClose />
          <DialogTitle>{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <p className="!text-white">
              Are you sure you want to delete this chat?
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              className="!bg-orange-500 !text-white"
            >
              Cancel
            </Button>
            <Button onClick={handleDelete} color="danger">
              Delete
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </div>
  );
}

export default ChatCard;
